import { Component } from "react";
import { Card, Row, Col, Badge, Button } from "react-bootstrap";
import { GoSync } from "react-icons/go";
import { LuClipboardCopy, LuClipboardPaste } from "react-icons/lu";
import Tooltip from "../../controls/common/tooltip";
import Clipboard from "../../controls/common/clipboard";
import Converter from "../converter";
import Utils from "context/utils";

export default class DetailsPane extends Component {
  exportToClipboard() {
    const converter = new Converter(this.props.shell.shellInterface);
    const definition = converter.reactFlowToBlobhubDefinition(this.props.shell.nodes, this.props.shell.edges);
    const text = JSON.stringify(definition, null, 2)
    Clipboard.to(text);
  }

  importFromClipboard() {
    Clipboard.from().then((text) => {
      let definition = null;
      try {
        definition = JSON.parse(text);
        if ( definition["components"].length < 1 ) {
          return;
        }
      } catch (error) {
        console.error(error.message);
        return;
      }

      const converter = new Converter(this.props.shell.shellInterface);
      const [nodes, edges] = converter.blobhubDefinitionToReactFlow(definition, this.props.shell.manifest);

      // Offset new workflow to the right of an existing one
      // 1. Calculate the right boundary of an existing workflow
      let rightMost = 0;
      for ( const node of this.props.shell.nodes ) {
        let currentRight = node.position.x;
        for ( const port of node.data.component["ports"] ) {
          if ( "width" == port["route"] ) {
            currentRight += port["value"]["integer"];
          }
        }
        if ( currentRight > rightMost ) {
          rightMost = currentRight;
        }
      }
      // 2. Calculate the left boundary of a newly pasted workflow
      let leftMost = 0;
      for ( const node of nodes ) {
        if ( node.position.x < leftMost ) {
          leftMost = node.position.x;
        }
      }
      // 3. Compute offset
      const offset = Math.min(leftMost - rightMost, 0) * -1 + 25;
      // 4. Apply offset
      for ( const node of nodes ) {
        node.position.x += offset;
      }

      // Post process notes
      for ( const node of nodes ) {
        // Regenerate workflow IDs
        const newId = Utils.generateUniqueId();
        node.id = newId;
        node.data.component.id = newId;

        // Cleanup port values referring to local resources
        for ( const port of node.data.component["ports"] ) {
          if ( "workflow_definition_id" == port["value"]["type"] ) {
            port["value"]["id"] = "";
          }
        }
      }

      // Register enw nodes and edges
      const mergedNodes = this.props.shell.nodes.concat(nodes);
      const mergedEdges = this.props.shell.edges.concat(edges);
      this.props.shell.workflowInterface.setNodes(mergedNodes, true);
      this.props.shell.workflowInterface.setEdges(mergedEdges, true);
    });
  }

  render() {
    return (
      <>
        {this.props.shell.session &&
          <>
            <Card.Text as="h6">Session Objects</Card.Text>
            <hr />
            <Card.Text>
              {null != this.props.shell.sessionObjects ?
                <>
                  {0 != this.props.shell.sessionObjects.length ?
                    <>
                      {this.props.shell.sessionObjects.map((object, index) => {
                        const data = this.props.shell.sessionData[object.alias];

                        return (
                          <Row style={{"height": "33px"}}>
                            <Col className="d-flex align-items-center">
                              <Badge variant="secondary" className="title-tag-badge">{object.alias}</Badge>
                              &nbsp;&nbsp;
                              <span className="text-muted">alias</span>
                            </Col>
                            <Col sm={"auto"}  className="d-flex align-items-center">
                              {data &&
                                <>
                                  <span className="text-muted">value</span>
                                  &nbsp;&nbsp;
                                  <Clipboard text={JSON.stringify(data, null, 2)} />
                                </>
                              }
                            </Col>
                          </Row>
                        );
                      })}
                    </>
                  : <>No objects are available within the selected session.</> }
                </>
              : <>Loading session objects...</> }
            </Card.Text>

            {this.props.shell.shellInterface.canEditRevision() &&
              <>
                <Card.Text as="h6">Actions</Card.Text>
                <hr />
                <Card.Text style={{"margin-top": "20px"}}>
                  <Button variant="light" size="sm" onClick={() => {
                    this.props.shell.shellInterface.getSync().loadSessionContent();
                  }}><GoSync />&nbsp;&nbsp;Refresh Session Objects</Button>
                </Card.Text>
                <Card.Text>
                  Pulls the list of objects persisted within the session and loads its contents.
                </Card.Text>
              </>
            }
          </>
        }

        {!this.props.shell.session &&
          <Card.Text>
            Select session or widget to see its properties displayed here.
          </Card.Text>
        }

        <Card.Text as="h6">Export</Card.Text>
        <hr />
        <Card.Text>
          <Row>
            <Col className="align-self-center">
              Export playground definition to clipboard.
            </Col>
            <Col sm={"auto"}>
              <span className="float-right">
                <Tooltip text="Export to clipboard">
                  <Button size="sm" variant="light" onClick={(event) => { this.exportToClipboard(); }}>
                    <LuClipboardCopy />
                  </Button>
                </Tooltip>
              </span>
            </Col>
          </Row>
        </Card.Text>

        {this.props.shell.shellInterface.canEditRevision() &&
          <>
            <Card.Text as="h6">Import</Card.Text>
            <hr />
            <Card.Text>
              <Row>
                <Col className="align-self-center">
                  Import playground definition from clipboard.
                </Col>
                <Col sm={"auto"}>
                  <span className="float-right">
                    <Tooltip text="Import from clipboard">
                      <Button size="sm" variant="light" onClick={(event) => { this.importFromClipboard(); }}>
                        <LuClipboardPaste />
                      </Button>
                    </Tooltip>
                  </span>
                </Col>
              </Row>
            </Card.Text>
          </>
        }
      </>
    );
  }
}
