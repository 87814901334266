/**
 * Class to store static application configuration
 */
export default class Config {

  /**
   * General
   */

  // Unlocks debugging features and verbose logging.
  static debug = true;

  static apiBaseUrl() {
    return "https://api.blobhub.io";
  }

  static staticBaseUrl() {
    return "https://static.blobhub.io";
  }

  static authGoogleClientId() {
    return "190294057847-km6i4usvtespqqs8o4avrbkl9oaglv86.apps.googleusercontent.com";
  }

  static analyticsGoogleId() {
    return "UA-145460975-1";
  }

  /**
   * ONNX
   */

  static maxUploadFileSize = 4 * 1024 * 1024;
  static maxDownloadFileSize = 4 * 1024 * 1024;
}
