import React, { Component } from 'react';
import { Card, Form, Badge, Button, ButtonGroup, ToggleButton, Row, Col, Modal, Table, Alert } from 'react-bootstrap';
import { GoX } from "react-icons/go";
import { GrDocument } from "react-icons/gr";
import { LuTableProperties, LuComponent } from "react-icons/lu";
import { CgDetailsMore } from "react-icons/cg";
import Tooltip from "../../controls/common/tooltip";
import AceEditor from "react-ace";
import Select from "react-select";
import Utils from "context/utils";

export default class EditMessageContentModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      portValue: null,
      viewOption: "content",
      fields: {},
      errors: {}
    };

    this.viewOptions = [
      { label: <GrDocument/>, value: "content", tooltip: "Content" },
      { label: <LuTableProperties/>, value: "attributes", tooltip: "Attributes" },
      { label: <LuComponent/>, value: "providers", tooltip: "Providers" },
      { label: <CgDetailsMore/>, value: "metadata", tooltip: "Metadata" },
    ];
  }

  createFieldValue(field) {
    return this.props.portValue.message[field]
      ? JSON.stringify(this.props.portValue.message[field], null, 2) : "";
  }

  setError(error, message) {
    this.state.errors[error] = message;
    this.setState({errors: this.state.errors});
  }

  clearError(error) {
    delete this.state.errors[error];
    this.setState({errors: this.state.errors});
  }

  onEnter = () => {
    const portValue = Utils.deepCopy(this.props.portValue);
    this.setState({
      portValue: portValue,
      fields: {
        "attributes": this.createFieldValue("attributes"),
        "providers": this.createFieldValue("providers"),
        "metadata": this.createFieldValue("metadata")
      },
      errors: {},
      viewOption: "content"
    });
  }

  handleUpdate = () => {
    // Update message
    this.props.portValue.message = this.state.portValue.message;
    this.props.shell.shellInterface.markModified();

    // Close modal
    this.props.onHide();
  }

  /**
   * Root Properties
   */

  renderRootProperties() {
    let messageDate = "n/a";
    if ( this.state.portValue.message["attributes"] && this.state.portValue.message["attributes"]["created_at"] ) {
      messageDate = Utils.formatDateTime(this.state.portValue.message["attributes"]["created_at"]);
    }

    return (
      <Row style={{"margin-bottom": "12px"}}>
        <Col sm={"auto"} className="d-flex align-items-center">
          <span className="text-muted" style={{"font-size": "14px"}}>
            Role
          </span>
          &nbsp;&nbsp;
          <Badge variant="secondary" className="title-tag-badge">
            {this.state.portValue.message["role"]}
          </Badge>
          &nbsp;&nbsp;
          <span className="text-muted" style={{"font-size": "14px"}}>
            Time
          </span>
          &nbsp;&nbsp;
          <Badge variant="secondary" className="title-tag-badge">
            {messageDate}
          </Badge>
        </Col>
        <Col>
          <span className="float-right">
            <ButtonGroup toggle size="sm">
              {this.viewOptions.map((option, index) => (
                <Tooltip text={option.tooltip}>
                  <ToggleButton
                    key={index}
                    type="radio"
                    variant="light"
                    name="radio"
                    value={option.value}
                    checked={this.state.viewOption === option.value}
                    onChange={(event) => { this.setState({ viewOption: event.currentTarget.value }) }}
                  >
                    {option.label}
                  </ToggleButton>
                </Tooltip>
              ))}
            </ButtonGroup>
          </span>
        </Col>
      </Row>
    );
  }

  /**
   * Content Editor
   */

  handleAddTextContentItem() {
    this.state.portValue.message.content.push({
      "type": "text",
      "text": ""
    });
    this.setState({ portValue: this.state.portValue });
  }

  handleRemoveContentItem(index) {
    this.state.portValue.message.content.splice(index, 1);
    this.setState({ portValue: this.state.portValue });
  }

  renderContent() {
    return (
      <>
        <Table hover bordered>
          {this.state.portValue.message.content.map((contentItem, index) => {
            return (
              <tr>
                <td>
                  <div>
                    <Form.Row className="align-items-center" style={{ "margin-bottom": "10px" }}>
                      <Col xs="auto">
                        <span className="text-muted" style={{"font-size": "12px"}}>
                          Type
                        </span>
                      </Col>
                      <Col xs="auto">
                        <Badge variant="secondary" className="title-tag-badge">
                          text
                        </Badge>
                      </Col>
                      <Col>
                        <span className="float-right">
                          {this.props.shell.shellInterface.canEditRevision() &&
                            <Tooltip text="Remove content item">
                              <Button variant="light" size="sm" onClick={() => this.handleRemoveContentItem(index)}>
                                <GoX />
                              </Button>
                            </Tooltip>
                          }
                        </span>
                      </Col>
                    </Form.Row>
                    <Form.Row className="align-items-center">
                      <Col>
                        <AceEditor
                          name="message-content-item-editor"
                          width="100%"
                          mode="text"
                          theme="monokai"
                          wrapEnabled="true"
                          readOnly={!this.props.shell.shellInterface.canEditRevision()}
                          value={contentItem.text}
                          minLines={3}
                          maxLines={30}
                          onChange={(newValue) => {
                            contentItem.text = newValue;
                          }}
                        />
                      </Col>
                    </Form.Row>
                  </div>
                </td>
              </tr>
            );
          })}
        </Table>

        {this.props.shell.shellInterface.canEditRevision() &&
          <>
            <Button variant="success" size="sm" onClick={() => this.handleAddTextContentItem()}>
              Add Text
            </Button>
            <span className="text-muted text-note">
              &nbsp;&nbsp;&nbsp;Add new content item of "text" type
            </span>
          </>
        }
      </>
    );
  }

  /**
   * Message Fields (attributes, providers, metadata)
   */

  handleAddMessageField(field) {
    this.state.portValue.message[field] = {};
    this.state.fields[field] = "{}";
    this.setState({
      portValue: this.state.portValue,
      fields: this.state.fields
    });
  }

  handleUpdateMessageField(field, newValue) {
    // Update text value
    this.state.fields[field] = newValue;
    this.setState({fields: this.state.fields});

    // Update serialized object
    try {
      this.state.portValue.message[field] = JSON.parse(newValue);
      this.setState({portValue: this.state.portValue});
      this.clearError(`${field}_invalid_json`);
    } catch (error) {
      this.setError(`${field}_invalid_json`, `\"${field}\" field contains invalid JSON`);
    }
  }

  handleRemoveMessageField(field) {
    delete this.state.portValue.message[field];
    delete this.state.fields[field];
    this.setState({
      portValue: this.state.portValue,
      fields: this.state.fields
    });
  }

  renderMessageField(field) {
    if ( !this.state.portValue.message[field] ) {
      return (
        <>
          <Row>
            <Col style={{"padding-right": "0px"}}>
              <Alert variant="info">
                Message "{field}" field is not specified.
              </Alert>
            </Col>
            {this.props.shell.shellInterface.canEditRevision() &&
              <Col sm="auto">
                <Button variant="success" size="sm" onClick={() => this.handleAddMessageField(field)}>
                  Add
                </Button>
              </Col>
            }
          </Row>
        </>
      );
    }
    return (
      <>
        <Form.Row className="align-items-center" style={{ "margin-bottom": "10px" }}>
          <Col xs="auto">
            <span className="text-muted" style={{"font-size": "12px"}}>
              Field
            </span>
          </Col>
          <Col xs="auto">
            <Badge variant="secondary" className="title-tag-badge">
              {field}
            </Badge>
          </Col>
          <Col>
            <span className="float-right">
              {this.props.shell.shellInterface.canEditRevision() &&
                <Tooltip text={`Remove \"${field}\" field`}>
                  <Button variant="light" size="sm" onClick={() => this.handleRemoveMessageField(field)}>
                    <GoX />
                  </Button>
                </Tooltip>
              }
            </span>
          </Col>
        </Form.Row>

        <Card.Text>
          <AceEditor
            name="playground-widget-message-attributes"
            width="100%"
            mode="json"
            theme="monokai"
            wrapEnabled="true"
            readOnly={!this.props.shell.shellInterface.canEditRevision()}
            value={this.state.fields[field]}
            onChange={(newValue) => this.handleUpdateMessageField(field, newValue)}
            minLines={3}
            maxLines={30}
          />
        </Card.Text>
      </>
    );
  }

  /**
   * Main Renderer
   */

  render() {
    const hasError = Object.keys(this.state.errors).length > 0;

    return (
      <Modal
        {...this.props}
        onEnter={this.onEnter}
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Edit Message Content
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.portValue &&
            <>
              {this.renderRootProperties()}

              <div className={"content" == this.state.viewOption ? "" : "hidden"}>
                {this.renderContent()}
              </div>
              <div className={"attributes" == this.state.viewOption ? "" : "hidden"}>
                <Table bordered hover>
                  <tbody><tr><td>
                    {this.renderMessageField("attributes")}
                  </td></tr></tbody>
                </Table>
              </div>
              <div className={"providers" == this.state.viewOption ? "" : "hidden"}>
                <Table bordered hover>
                  <tbody><tr><td>
                    {this.renderMessageField("providers")}
                  </td></tr></tbody>
                </Table>
              </div>
              <div className={"metadata" == this.state.viewOption ? "" : "hidden"}>
                <Table bordered hover>
                  <tbody><tr><td>
                    {this.renderMessageField("metadata")}
                  </td></tr></tbody>
                </Table>
              </div>
            </>
          }
        </Modal.Body>
        <Modal.Footer>
          {hasError &&
            <span style={{"padding-right": "10px"}}>
              <Alert variant="warning">
                {this.state.errors[Object.keys(this.state.errors)[0]]}
              </Alert>
            </span>
          }

          <Button variant="light" onClick={this.props.onHide}>Close</Button>
          {this.props.shell.shellInterface.canEditRevision() &&
            <Button variant="success" disabled={hasError} onClick={this.handleUpdate}>Apply</Button>
          }
        </Modal.Footer>
      </Modal>
    );
  }
}
