import React, { Component } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import {
  ReactFlow,
  ReactFlowProvider,
  Controls,
  MiniMap,
  Background,
  BackgroundVariant,
  applyNodeChanges,
  applyEdgeChanges,
  useReactFlow,
  addEdge
} from '@xyflow/react';
import { GoPlus, GoInfo } from 'react-icons/go';
import { GrRevert } from "react-icons/gr";
import { TfiSave } from "react-icons/tfi";
import { BiExpandHorizontal, BiCollapseHorizontal } from "react-icons/bi";

import Tooltip from "../../controls/common/tooltip";

import LibraryPane from "../../controls/library/index";
import DetailsPane from "../details/index";

import ChatNode from "../widgets/chat/index";
import MessageNode from "../widgets/message/index";
import MessagesNode from "../widgets/messages/index";

import Converter from "./../converter";
import "./index.css";

const FlowWrapper = ({app, shell, shown, ...rest}) => {
  const converter = new Converter(shell.shellInterface);

  const nodeTypes = {
    "widget.chat": ChatNode,
    "widget.message": MessageNode,
    "widget.messages": MessagesNode
  };

  const onNodesChange = (changes) => {
    // Mark workflow as modified if impacted
    const isModified = converter.doReactFlowChangesImpactDefinition(changes);

    // Ignore changes occurring
    // - revision is not editable
    // - while the component is hidden
    if ( isModified && ( !shell.shellInterface.canEditRevision() || !shown ) ) {
      return;
    }

    // Apply changes
    shell.workflowInterface.setNodes(applyNodeChanges(changes, shell.nodes), isModified);
  };

  const onEdgesChange = (changes) => {
    // Mark workflow as modified if impacted
    const isModified = converter.doReactFlowChangesImpactDefinition(changes);

    // Ignore changes occurring
    // - revision is not editable
    // - while the component is hidden
    if ( isModified && ( !shell.shellInterface.canEditRevision() || !shown ) ) {
      return;
    }

    // Apply changes
    shell.workflowInterface.setEdges(applyEdgeChanges(changes, shell.edges), isModified);
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const { screenToFlowPosition } = useReactFlow();

  const onDrop = (event) => {
    let componentDefinition = {};

    try {
      // Retrieve component definition from the event
      componentDefinition = JSON.parse(event.dataTransfer.getData("text/plain"));
    } catch {
      return;
    }

    // Prevent default action
    event.preventDefault();

    // Determine position of the dropped element
    const position = screenToFlowPosition({
      x: event.clientX,
      y: event.clientY,
    });

    // Create new node
    const node = converter.createNewNode(componentDefinition, position, shell.nodes);

    // Add node to the global list
    shell.workflowInterface.setNodes(shell.nodes.concat(node), true);
  };

  const onSelectionChange = (params) => {
    if ( 1 == params.nodes.length && 0 == params.edges.length ) {
      shell.shellInterface.updateDetails("component", params.nodes[0]);
    } else if ( 0 == params.nodes.length && 1 == params.edges.length ) {
      shell.shellInterface.updateDetails("connection", params.edges[0]);
    } else {
      shell.shellInterface.updateDetails(null, null);
    }
  }

  return (
    <ReactFlow
      nodes={shell.nodes}
      edges={shell.edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onSelectionChange={onSelectionChange}
      nodeTypes={nodeTypes}
      fitView
      attributionPosition="bottom-right"
      {...rest}
    >
      <Controls
        position="bottom-left"
        showInteractive={false}
      />
      <Background id="playground-background" variant={BackgroundVariant.Dots} gap={12} size={1} />
    </ReactFlow>
  )
}

export default class Workspace extends Component {
  render() {
    // Disable editing entirely if
    // - revision is not editable
    const disabled = !this.props.shell.shellInterface.canEditRevision();

    const containerContentClass
      = this.props.shell.wideContent || this.props.shell.library.visible || this.props.shell.details.visible
      ? "content-row-wide"
      : "content-row";

    return (
      <>
        <Container className={"blob-header-row " + containerContentClass}>
          <Row>
            <Col sm={3} className={this.props.shell.library.visible ? "" : "hidden"}>
              <LibraryPane shell={this.props.shell} />
            </Col>

            <Col>
              <Card>
                <Card.Body>
                  <div className="workflow-controls-pane-left">
                    {!this.props.shell.library.visible &&
                      <Button variant="light" onClick={() => {
                        this.props.shell.shellInterface.updatePane("library", true);
                      }}><GoPlus/></Button>
                    }
                  </div>

                  <div className="workflow-controls-pane-right">
                    {this.props.shell.modified &&
                      <>
                        <Tooltip text="Revert all changes">
                          <Button variant="light" onClick={() => {
                            this.props.shell.shellInterface.revertPlayground();
                          }}><GrRevert /></Button>
                        </Tooltip>
                        &nbsp;&nbsp;
                        <Tooltip text="Save changes">
                          <Button variant="light" onClick={() => {
                            this.props.shell.shellInterface.savePlayground();
                          }}><TfiSave /></Button>
                        </Tooltip>
                        &nbsp;&nbsp;
                      </>
                    }

                    <Tooltip text="Toggle width">
                      <Button variant="light" onClick={() => {
                        this.props.shell.shellInterface.updateState({ wideContent: !this.props.shell.wideContent });
                      }}>
                        {this.props.shell.wideContent ? <BiCollapseHorizontal /> : <BiExpandHorizontal />}
                      </Button>
                    </Tooltip>

                    {!this.props.shell.details.visible &&
                      <>
                        &nbsp;&nbsp;
                        <Tooltip text="Show details">
                          <Button variant="light" onClick={() => {
                            this.props.shell.shellInterface.updatePane("details", true);
                          }}><GoInfo/></Button>
                        </Tooltip>
                      </>
                    }
                  </div>

                  <div className="playground-editor playground-editor-surface">
                    <ReactFlowProvider key="playground-editor">
                      <FlowWrapper
                        app={this.props.app}
                        shell={this.props.shell}
                        shown={this.props.shown}
                        edgesUpdatable={!disabled}
                        edgesFocusable={!disabled}
                        nodesDraggable={!disabled}
                        nodesConnectable={!disabled}
                        nodesFocusable={!disabled}
                        zoomOnDoubleClick={!disabled}
                      />
                    </ReactFlowProvider>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col sm={3} className={this.props.shell.details.visible ? "" : "hidden"}>
              <DetailsPane shell={this.props.shell} />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
