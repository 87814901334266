import React, { Component } from 'react';
import { Container, Row, Col, Card, Form, Alert, Badge, ButtonGroup, ToggleButton, Button } from 'react-bootstrap';
import { marked, Renderer } from 'marked';
import { AgGridReact } from 'ag-grid-react';
import { GoX, GoInfo } from "react-icons/go";
import { MdOutlineExpandMore, MdOutlineExpandLess } from "react-icons/md";
import { FaRegStopCircle } from "react-icons/fa";
import AceEditor from "react-ace";
import Clipboard from "../../controls/common/clipboard";
import Utils from 'context/utils';
import Preview from "./preview";
import Event from "./event";
import "./index.css"

export default class ExecutionView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      autoRefresh: true,
      execution: null,
      executionEvents: null,
      currentEvent: null,
      currentEventDataItem: null,
      currentProcessorId: null,
      eventsProgress: {
        progress: false,
        error: false,
        message: null
      },
      definition: null,
      definitionObject: null,
      definitionProgress: {
        progress: false,
        error: false,
        message: null
      },
      detailsOption: "details"
    };

    this.detailsOptions = [
      { label: "Details", value: "details" },
      { label: "Data", value: "data" }
    ];

    this.mounted = false;
    this.dataItemsCache = {};
  }

  updateEventsProgress(progress, error, message) {
    this.setState({
      eventsProgress: {
        progress: progress,
        error: error,
        message: message
      }
    });
  }

  componentDidMount() {
    this.mounted = true;
    this.loadInitialEvents();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  loadInitialEvents() {
    this.loadExecutionEventsCore(null, null, false);
    this.continueAutoRefresh();
  }

  continueAutoRefresh() {
    if ( !this.mounted ) {
      return;
    }
    setTimeout(() => {
      // Issue refresh if enabled, and still in non-terminal state
      if ( this.state.autoRefresh && this.state.execution &&
        !this.props.app.api.hasWorkflowExecutionReachedTerminalStatus(this.state.execution) ) {
        this.loadNewerEvents();
      }

      // Continue timer unconditionally
      this.continueAutoRefresh();
    }, 1000);
  }

  loadOlderEvents() {
    let createdBefore = null;
    if ( this.state.executionEvents && this.state.executionEvents.length > 0 ) {
      createdBefore = this.state.executionEvents[0]["created_at"];
    }
    this.loadExecutionEventsCore(null, createdBefore, false);
  }

  loadNewerEvents() {
    let createdSince = null;
    if ( this.state.executionEvents && this.state.executionEvents.length > 0 ) {
      createdSince = this.state.executionEvents[this.state.executionEvents.length - 1]["created_at"];
    }
    this.loadExecutionEventsCore(createdSince, null, true);
  }

  loadExecutionEventsCore(createdSince, createdBefore, ascending) {
    // Render progress
    this.updateEventsProgress(true, false, "Loading events...");

    // Fetch the list
    const executionId = this.props.match.params.subSectionId;
    const body = {
      "engine": "workflow_blobhub",
      "command": "list_execution_events",
      "execution_id": executionId,
      "include_execution": true,
      "ascending": ascending
    };
    if ( createdSince ) {
      body["created_since"] = createdSince;
    }
    if ( createdBefore ) {
      body["created_before"] = createdBefore;
    }
    this.props.app.api.revisionsIdDataQueryPost(this.props.revision.id, body)
      .then( response => {
        // Parse response
        const execution = response.data["execution"];
        const executionEvents = response.data["execution_events"];

        // Clear progress
        this.updateEventsProgress(false, false, null);

        // Prepare state update
        let updatedState = {
          execution: execution
        };

        // Any new events received?
        if ( executionEvents.length > 0 ) {
          // Merge results with previously loaded ones
          let mergedExecutionEvents = [];
          mergedExecutionEvents.push(...( this.state.executionEvents || [] ));
          mergedExecutionEvents.push(...executionEvents);
          mergedExecutionEvents.sort((a, b) => a["created_at"].localeCompare(b["created_at"]));

          // Update current processor
          let currentEvent = null;
          let currentProcessorId = null;
          if ( mergedExecutionEvents.length > 0 ) {
            const latestEvent = mergedExecutionEvents[mergedExecutionEvents.length - 1];
            if ( latestEvent["attributes"] && latestEvent["attributes"]["processor_id"] ) {
              currentEvent = latestEvent;
              currentProcessorId = latestEvent["attributes"]["processor_id"];
            }
          }

          updatedState = {...updatedState, ...{
            executionEvents: mergedExecutionEvents,
            currentProcessorId: currentProcessorId,
            currentEvent: currentEvent,
            currentEventDataItem: null
          }};
        }

        // Update component state
        this.setState(updatedState, () => {
          this.loadDefinition();
          this.loadEventData();
        });
      }, error => {
        // Display failure message
        this.updateEventsProgress(false, true, "Failed to fetch the list of events: " + error.message);
      });
  }

  updateDefinitionProgress(progress, error, message) {
    this.setState({
      definitionProgress: {
        progress: progress,
        error: error,
        message: message
      }
    });
  }

  loadDefinition() {
    if ( null == this.state.execution || null != this.state.definition ) {
      return;
    }

    this.updateDefinitionProgress(true, false, null);

    const body = {
      engine: "workflow_blobhub",
      command: "download_definition",
      definition_id: this.state.execution["definition_id"]
    };
    this.props.app.api.revisionsIdDataQueryPost(this.props.revision.id, body)
      .then( response => {
        const definition = response.data["definition"];
        const definitionObject = response.data["definition_object"];
        this.setState({
          definition: definition,
          definitionObject: definitionObject
        });
        this.updateDefinitionProgress(false, false, null);
      }, error => {
        this.updateDefinitionProgress(false, true, "Failed to load definition: " + error.message);
      });
  }

  loadEventData() {
    // No data associated with the event.
    if ( null == this.state.currentEvent ||
      !this.state.currentEvent["data"] ||
      !this.state.currentEvent["data"]["data_id"] ) {
      return;
    }
    // Is it being loaded?
    if ( this.state.currentEvent["data"]["progress"] ) {
      return;
    }
    // Is it cached?
    const dataId = this.state.currentEvent["data"]["data_id"];
    if ( this.dataItemsCache[dataId] ) {
      this.setState({currentEventDataItem: this.dataItemsCache[dataId]});
      return;
    }

    let currentEvent = this.state.currentEvent;
    currentEvent["data"]["progress"] = {
      progress: true, error: false, message: "Loading event data..."
    };
    this.setState({currentEvent: currentEvent});

    const body = {
      "engine": "workflow_blobhub",
      "command": "download_execution_event_data",
      "revision_id": this.state.execution["revision_id"],
      "session_id": this.state.execution["session_id"],
      "execution_id": this.state.execution["id"],
      "data_id": dataId
    };
    this.props.app.api.revisionsIdDataQueryPost(this.props.revision.id, body)
      .then( response => {
        const dataItem = response.data;

        // Cache data item
        this.dataItemsCache[dataId] = dataItem;

        // Clear progress
        currentEvent["data"]["progress"] = null;

        // Render it only if the event is still active
        if ( null != this.state.currentEvent && currentEvent["id"] == this.state.currentEvent["id"] ) {
          this.setState({
            currentEvent: currentEvent,
            currentEventDataItem: dataItem
          });
        }
      }, error => {
        // Update progress
        currentEvent["data"]["progress"] = {
          progress: false, error: true, message: "Failed to load event data: " + error.message
        };

        // Apply if the event is still active
        if ( null != this.state.currentEvent && currentEvent["id"] == this.state.currentEvent["id"] ) {
          this.setState({currentEvent: currentEvent});
        }
      });
  }

  renderMessage(message) {
    var renderer = new Renderer();
    renderer.table = function(table) {
      let contents = marked.parse(table.raw);
      contents = contents.replace("<table>", '<table class="table table-hover">');
      return marked.parse(contents);
    };
    renderer.image = function(image) {
      return `<img src="${image.href}" alt="${image.text}" width="100%" />`;
    };
    var renderedMarkdown = {__html: marked.parse(message, { renderer: renderer })};
    return ( <span dangerouslySetInnerHTML={renderedMarkdown} /> );
  }

  render() {
    const lineHeight = 22;
    const cellStyle = {lineHeight: "${lineHeight}px"};
    let columns = [
      {
        "field": "id",
        "headerName": "ID",
        "filter": false,
        "sortable": false,
        "flex": 1,
        cellStyle: cellStyle,
        autoHeight: true,
        cellRenderer: params => {
          return (
            <span className="execution-event-item">
              <Event event={params.data} />
            </span>
          );
        }
      }
    ];

    return (
      <>
        <Container className="content-row-wide" style={{"padding-top": "0px"}}>
          <Row>
            <Col sm={3}>
              <Card>
                <Card.Header>
                  Overview
                </Card.Header>
                <Card.Body>
                  <Card.Text as="h6">
                    Execution
                    <span className="float-right execution-events-details-pane-right">
                      <Clipboard text={JSON.stringify(this.state.execution, null, 2)} />
                    </span>
                  </Card.Text>
                  <hr />
                  {this.state.execution ?
                    <Card.Text>
                      <table className="properties">
                        <tr>
                          <td className="prop-name-column">ID</td>
                          <td className="prop-value-column"><code>{this.state.execution.id}</code></td>
                        </tr>
                        <tr>
                          <td className="prop-name-column">Definition</td>
                          <td className="prop-value-column"><code>{this.state.execution["definition_id"]}</code></td>
                        </tr>
                        {this.state.definition &&
                          <tr>
                            <td className="prop-name-column"></td>
                            <td className="prop-value-column">{this.state.definition["alias"]}</td>
                          </tr>
                        }
                        <tr>
                          <td className="prop-name-column">Created</td>
                          <td className="prop-value-column">{Utils.formatDateTime(this.state.execution["created_at"])}</td>
                        </tr>
                        <tr>
                          <td className="prop-name-column"></td>
                          <td className="prop-value-column">{Utils.formatTimeAgo(this.state.execution["created_at"])}</td>
                        </tr>
                        {this.state.execution["started_at"] &&
                          <tr>
                            <td className="prop-name-column">Started</td>
                            <td className="prop-value-column">{Utils.formatDateTime(this.state.execution["started_at"])}</td>
                          </tr>
                        }
                        {this.state.execution["ended_at"] &&
                          <tr>
                            <td className="prop-name-column">Ended</td>
                            <td className="prop-value-column">{Utils.formatDateTime(this.state.execution["ended_at"])}</td>
                          </tr>
                        }
                        <tr>
                          <td className="prop-name-column">Status</td>
                          <td>
                            <Badge bg="info" className="title-tag-badge">{this.state.execution["status"]}</Badge>
                          </td>
                        </tr>
                      </table>
                    </Card.Text> : <Card.Text>
                      <Alert variant="primary">
                        Loading execution details...
                      </Alert>
                    </Card.Text>
                  }
                  <Card.Text as="h6">Workflow</Card.Text>
                  <hr />
                  <Card.Text>
                    <Preview
                      app={this.props.app}
                      revision={this.props.revision}
                      execution={this.state.execution}
                      definition={this.state.definitionObject}
                      definitionProgress={this.state.definitionProgress}
                      currentProcessorId={this.state.currentProcessorId}
                    />
                  </Card.Text>

                  {this.props.canEditRevision &&
                    <>
                      <Card.Text as="h6">Actions</Card.Text>
                      <hr />
                      <Card.Text>
                        <Button disabled={true}  variant="success" size="sm" onClick={() => {
                          // TODO:
                        }}><FaRegStopCircle /> Stop Execution</Button>
                      </Card.Text>
                      <Card.Text>
                        Terminates processing of an active execution leaving the rest of remaining flow unfinished.
                      </Card.Text>
                    </>
                  }
                </Card.Body>
              </Card>
            </Col>

            <Col sm={4}>
              <Card>
                <Card.Header>
                  Events
                  <span className="float-right">
                    {this.state.eventsProgress.progress &&
                      <span>{this.state.eventsProgress.message}</span>
                    }
                    {this.state.eventsProgress.error &&
                        <Alert variant="warning" style={{
                          "display": "inline-block",
                          "padding-top": "0px",
                          "padding-bottom": "0px"
                        }}>{this.state.eventsProgress.message}</Alert>
                    }
                  </span>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <Button disabled={this.state.eventsProgress.progress} variant="light" size="sm" onClick={() => {
                      this.loadOlderEvents();
                    }}><MdOutlineExpandLess /> Load Older Events</Button>
                  </Card.Text>
                  <Card.Text>
                    <div
                      className="ag-theme-quartz execution-events-grid"
                      id="execution-events-grid"
                    >
                      <AgGridReact
                        rowData={this.state.executionEvents}
                        getRowId={(params) => {
                          return params.data.id;
                        }}
                        columnDefs={columns}
                        domLayout="normal"
                        pagination={false}
                        suppressScrollOnNewData={true}
                        animateRows={false}
                        autoSizeStrategy={{
                          type: "fitGridWidth"
                        }}
                        headerHeight={0}
                        rowHeight={lineHeight}
                        rowSelection={"single"}
                        onRowSelected={(event) => {
                          const selectedRows = event.api.getSelectedRows();
                          if ( 0 == selectedRows.length ) {
                            this.setState({
                              currentEvent: null,
                              currentEventDataItem: null,
                              currentProcessorId: null,
                            });
                            return;
                          }

                          const currentEvent = selectedRows[0];
                          if ( currentEvent != this.state.currentEvent ) {
                            this.setState({
                              currentEvent: currentEvent,
                              currentEventDataItem: null
                            }, () => {
                              this.loadEventData();
                            });
                          }

                          let currentProcessorId = null;
                          if ( currentEvent["attributes"] && currentEvent["attributes"]["processor_id"] ) {
                            currentProcessorId = currentEvent["attributes"]["processor_id"];
                          }
                          if ( currentProcessorId != this.state.currentProcessorId ) {
                            this.setState({currentProcessorId: currentProcessorId});
                          }
                        }}
                        onRowDataUpdated={(event) => {
                          setTimeout(() => {
                            const index = event.api.getDisplayedRowCount() - 1;
                            event.api.ensureIndexVisible(index, "bottom");
                          }, 200);
                        }}
                        onFirstDataRendered={(event) => {
                          setTimeout(() => {
                            const index = event.api.getDisplayedRowCount() - 1;
                            event.api.ensureIndexVisible(index, "bottom");
                          }, 200);
                        }}
                      />
                    </div>
                  </Card.Text>
                  <Card.Text>
                    <Form.Row className="align-items-center">
                      <Col sm={5}>
                        <Button disabled={this.state.eventsProgress.progress} variant="light" size="sm" onClick={() => {
                          this.loadNewerEvents(null);
                        }}><MdOutlineExpandMore /> Load Newer Events</Button>
                      </Col>
                      <Col>
                        <Form.Check
                          id="execution-events-auto-refresh-switch"
                          type="switch" label="Auto refresh until terminal status"
                          checked={this.state.autoRefresh}
                          onChange={(event) => {
                            this.setState({ autoRefresh: event.target.checked });
                          }}
                        />
                      </Col>
                    </Form.Row>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col sm={5}>
              <Card>
                <Card.Header>
                  {"details" == this.state.detailsOption &&
                    <>Event Details</>
                  }
                  {"data" == this.state.detailsOption &&
                    <>Event Data</>
                  }
                </Card.Header>
                <Card.Body>
                  <span className="float-right execution-events-details-pane-right">
                    <ButtonGroup toggle size="sm">
                      {this.detailsOptions.map((option, index) => (
                        <ToggleButton
                          key={index}
                          type="radio"
                          variant="light"
                          name="radio"
                          value={option.value}
                          checked={this.state.detailsOption === option.value}
                          onChange={(event) => { this.setState({ detailsOption: event.currentTarget.value }) }}
                        >
                          {option.label}
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                  </span>

                  {this.state.currentEvent &&
                    <>
                      {"details" == this.state.detailsOption &&
                        <>
                          {this.state.currentEvent["message"] &&
                            <>
                              <Card.Text as="h6">Message</Card.Text>
                              <hr />
                              <Card.Text className="execution-events-details">
                                {this.renderMessage(this.state.currentEvent["message"])}
                              </Card.Text>
                            </>
                          }

                          <Card.Text as="h6">
                            Event
                            <span className="float-right execution-events-details-pane-right">
                              <Clipboard text={JSON.stringify(this.state.currentEvent, null, 2)} />
                              &nbsp;&nbsp;
                            </span>
                          </Card.Text>
                          <hr />
                          <Card.Text>
                            <AceEditor
                              name="event-viewer"
                              width="100%" minLines={3} maxLines={43}
                              mode="json" theme="monokai"
                              readOnly="true"
                              wrapEnabled="true"
                              value={JSON.stringify(this.state.currentEvent, null, 2)}
                            />
                          </Card.Text>
                        </>
                      }
                      {"data" == this.state.detailsOption &&
                        <>
                          {(!this.state.currentEvent["data"] || !this.state.currentEvent["data"]["data_id"] ) ?
                            <>
                              Selected event does not have additional data associated with it.
                            </> : <>
                              {this.state.currentEvent["data"]["progress"] &&
                                <>
                                  <Alert variant={this.state.currentEvent["data"]["progress"]["progress"] ? "primary" : "warning"}>
                                    {this.state.currentEvent["data"]["progress"]["message"]}
                                  </Alert>
                                </>
                              }
                              {this.state.currentEventDataItem &&
                                <>
                                  <Card.Text as="h6">
                                    Data
                                    <span className="float-right execution-events-details-pane-right">
                                      <Clipboard text={JSON.stringify(this.state.currentEventDataItem, null, 2)} />
                                      &nbsp;&nbsp;
                                    </span>
                                  </Card.Text>
                                  <hr />
                                  <Card.Text>
                                    <AceEditor
                                      name="event-viewer"
                                      width="100%" minLines={3}
                                      style={{
                                        "height": "calc(100vh - 420px)",
                                        "min-height": "767px"
                                      }}
                                      mode="json" theme="monokai"
                                      wrapEnabled="true"
                                      readOnly="true"
                                      value={JSON.stringify(this.state.currentEventDataItem, null, 2)}
                                    />
                                  </Card.Text>
                                </>
                              }
                            </>
                          }
                        </>
                      }
                    </>
                  }
                  {!this.state.currentEvent &&
                    <Card.Text>
                      Select an event to see its details presented here.
                    </Card.Text>
                  }
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
