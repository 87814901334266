import React, { Component } from "react";
import { Container, Card, Button, Alert, Badge } from "react-bootstrap";
import { GoX } from "react-icons/go";
import { FaCheck } from "react-icons/fa6";
import { MdInfoOutline, MdWarningAmber } from "react-icons/md";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { AgGridReact } from 'ag-grid-react';

export default class View extends Component {
  handleClose = () => {
    this.props.shell.shellInterface.updatePane("checker", false);
  }

  handleSelectComponent(event) {
    const componentId = event["component_id"];
    let nodes = [];
    for ( const node of this.props.shell.nodes ) {
      node.selected = node.data.processor["id"] == componentId;
      nodes.push({...node});
    }
    this.props.shell.workflowInterface.setNodes(nodes);
    let edges = [];
    for ( const edge of this.props.shell.edges ) {
      edge.selected = false;
      edges.push({...edge});
    }
    this.props.shell.workflowInterface.setEdges(edges);
  }

  render() {
    // Initialize events table
    const lineHeight = 34;
    const cellStyle = {lineHeight: "${lineHeight}px"};
    let columns = [
      {
        "field": "id",
        "headerName": "ID",
        "filter": false,
        "sortable": false,
        "flex": 1,
        cellStyle: cellStyle,
        autoHeight: true,
        cellRenderer: params => {
          return (
            <div className="execution-event-item">
              <span style={{"padding-right": "12px", "font-size": "16px"}}>
                {"info" == params.data["type"] &&
                  <MdInfoOutline />
                }
                {"warning" == params.data["type"] &&
                  <MdWarningAmber />
                }
                {"error" == params.data["type"] &&
                  <IoMdCloseCircleOutline />
                }
              </span>
              {params.data["message"]}
              {params.data["component_id"] &&
                <span className="float-right">
                  <Badge variant="secondary" className="title-tag-badge" style={{"font-size": "10px"}}>
                    {params.data["component_id"]}
                  </Badge>
                </span>
              }
            </div>
          );
        }
      }
    ];

    // Count event types
    let counts = {
      "info": 0,
      "warning": 0,
      "error": 0
    };
    for ( const event of this.props.shell.checker.events ) {
      ++counts[event["type"]];
    }

    // Render checker view
    return (
      <Container className="blob-header-row content-row">
        <Card>
          <Card.Body>
            <span className="workflow-controls-pane-right">
              <Button variant="light" onClick={this.handleClose}><GoX/></Button>
            </span>
            <Card.Text>
              Status:&nbsp;&nbsp;&nbsp;
              <Badge variant="secondary" className="title-tag-badge">
                {this.props.shell.checker.status}
              </Badge>
              &nbsp;&nbsp;&nbsp;
              <span className="text-muted">
                {counts["error"]} error(s), {counts["warning"]} warning(s), {counts["info"]} info(s)
              </span>
            </Card.Text>
            <Card.Text>
              <div className="ag-theme-quartz" id="editor-checker-events-grid">
                <AgGridReact
                  rowData={this.props.shell.checker.events}
                  columnDefs={columns}
                  domLayout="autoHeight"
                  pagination={false}
                  suppressScrollOnNewData={true}
                  animateRows={false}
                  autoSizeStrategy={{
                    type: "fitGridWidth"
                  }}
                  headerHeight={0}
                  rowHeight={lineHeight}
                  rowSelection={"single"}
                  onRowSelected={(event) => {
                    const selectedRows = event.api.getSelectedRows();
                    if ( 1 != selectedRows.length ) {
                      return;
                    }
                    const currentEvent = selectedRows[0];
                    this.handleSelectComponent(currentEvent);
                  }}
                />
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}
