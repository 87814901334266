import React, { Component } from 'react';
import { DropdownButton, Dropdown, ButtonToolbar, Badge } from 'react-bootstrap';
import Config from "context/config";
import ReactGA from 'react-ga';

export default class DownloaderView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewable: [],
      downloadable: []
    };

    this.sortObjects();
  }

  /**
   * Data Updates
   */

  sortObjects() {
    var viewable = [];
    var downloadable = [];
    for (var [index, object] of this.props.shell.data.objects.entries()) {
      // Remember global index since parent component relies on that.
      object.index = index;

      if ( object.viewable ) {
        viewable.push(object);
      }
      if ( object.downloadable ) {
        downloadable.push(object);
      }
    }

    this.state.viewable = viewable;
    this.state.downloadable = downloadable;
  }

  objectSelectedForViewing = (eventKey, event) => {
    const index = parseInt(eventKey);
    this.props.parent.activateObject(index);
  }

  objectSelectedForDownloading = (eventKey, event) => {
    ReactGA.event({
      category: "blob_download",
      action: `${this.props.blob.alias}`
    });
  }

  /**
   * Rendering Section
   */

  render() {
    var viewable = [];
    for (let [index, object] of this.state.viewable.entries()) {
      viewable.push(
        <>
          <Dropdown.Item
            active={this.props.parent.activeObjectIndex === object.index ? true: false}
            eventKey={index}
            onSelect={this.objectSelectedForViewing}>
            {object.name}
          </Dropdown.Item>
        </>
      );
    }

    var downloadable = [];
    for (const object of this.state.downloadable) {
      const downloadLink = `${Config.staticBaseUrl()}/revisions/${this.props.revision.id}/${object["path"]}`;
      downloadable.push(
        <>
          <Dropdown.Item
            href={downloadLink}
            onSelect={this.objectSelectedForDownloading}>
            {object.name} ({object.path})
          </Dropdown.Item>
        </>
      );
    }

    return (
      <>
        <div className="blob-download-row">
          <span>
            {this.props.parent.activeObjectDescr &&
              <>
                <Badge varient="light">
                  {this.props.parent.activeObjectDescr.name}
                </Badge>
                selected
              </>
            }
          </span>
          <span className="float-right">
            <ButtonToolbar>
              {this.state.viewable.length > 0 &&
                <>
                  <DropdownButton alignRight size="sm" title="Preview" variant="light" id="download">
                    {viewable}
                  </DropdownButton>
                </>
              }

              {this.state.downloadable.length > 0 &&
                <>
                  &nbsp;&nbsp;&nbsp;
                  <DropdownButton alignRight size="sm" title="Download" variant="success" id="download">
                    {downloadable}
                  </DropdownButton>
                </>
              }
            </ButtonToolbar>
          </span>
        </div>
      </>
    );
  }
}
